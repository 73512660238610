<template>
  <div class="table-game">
    <AddIncidence ref="addIncidenceComponent" @addResult="handleIncidence" />
    <div class="head">
      <div class="d-flex justify-content-between">
        <h2>RULETA: {{ PROVIDER_ID || 'No hay ruleta' }}</h2>
        <router-link to="/">
          <span class="text-end">
            <i
              class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            ></i
            >Ir al dashboard
          </span>
        </router-link>
      </div>
      <div
        class="position-absolute w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center loader flex-column"
        style="z-index: 1"
        v-if="loading"
      >
        <b-spinner label="Loading..."></b-spinner>
        <span class="fs-1">Buscando ronda... Espere por favor</span>
      </div>
    </div>

    <div :class="`table-container ${roundOpen ? 'openbets' : 'closebets'}`">
      <div class="tablero" ref="table">
        <div
          class="numbers-pleno"
          v-for="(button, index) in buttons"
          :key="index"
        >
          <div
            :class="`btn-container ${button.color}`"
            :data-value="button.number"
          >
            <button
              :class="`innerBtn ${button.color}`"
              :value="button"
              @click="handleClick(button)"
            >
              {{ button.number === 37 ? '00' : button.number }}
            </button>
            <div
              class="HoverBtns inactive"
              :data-number="button.number"
              data-hover-number
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="foot">
      <div class="round-info">
        <div class="text-muted display-6 text-center mb-4">
          <div v-if="roundOpen">
            Tiempo para <span>{{ timeResult }}</span
            >: {{ timeToBet }}
          </div>
          <div v-if="!roundOpen">{{ betMessage }}</div>
        </div>
        <div class="mx-auto mb-4 w-50">
          <b-progress height="2rem" :max="progressMax">
            <b-progress-bar
              :value="progressValue"
              animated
              :variant="barColor"
              class="mx-auto"
            >
              <span
                :class="`fw-bolder ${jackpotsOn ? 'text-dark' : 'text-light'}`"
                >{{ roundOpen ? 'HAGA SUS APUESTAS' : betMessage }}</span
              >
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div>
        <h3>
          Numero seleccionado:
          {{ numberSelected === 37 ? '00' : numberSelected }}
        </h3>
        <div class="d-flex config-result">
          <vs-button
            @click="addResult"
            primary
            :disabled="disableAddResult"
            :loading="addingResult"
          >
            Enviar resultado
          </vs-button>
          <vs-button
            @click="numberSelected = null"
            dark
            :disabled="!numberSelected"
          >
            Quitar seleccion
          </vs-button>
          <vs-button @click="handleProviderError">
            Añadir incidencia
          </vs-button>
        </div>
      </div>

      <div class="rounds w-100">
        <h5>Rounds</h5>
        <div v-if="roundsIds.length" class="rounds-container">
          <b-table striped hover :fields="fields" :items="roundsIds"> </b-table>
        </div>
      </div>

      <div class="rounds w-100">
        <h5>Resultados</h5>
        <div class="results">
          <div
            v-for="(element, index) in results"
            :key="index"
            :style="`background: ${element.color}`"
          >
            {{ element.result === 37 ? '00' : element.result }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import AddIncidence from './components/AddIncidence.vue';

export default {
  name: 'ManualResult',
  components: { AddIncidence },
  data() {
    return {
      numberSelected: null,
      title: 'ManualResult',
      roundOpen: false,
      timeResult: 'apostar',
      betMessage: 'Espere al siguiente juego',
      loading: false,
      progressMax: 0,
      progressValue: 30,
      barColor: 'success',
      doubleZeroRoulette: this.$route.params.doubleZero,
      buttons: [],
      roundsIds: [],
      fields: [
        { key: 'index', sortable: true },
        { key: 'PROVIDER_ROUND', sortable: false },
        // { key: "IDENTIFIER", sortable: false },
      ],
      results: [],
      jackpotsOn: false,
      SOCKET_URL: process.env.VUE_APP_API,
      socket: null,
      ROULETTE_ID: this.$route.params.id,
      PROVIDER_ID: this.$route.params.provider,
      currentRound: null,
      timeToBet: null,
      intervalo: null,
      runningInterval: false,
      addingResult: false,
      rouletteFisic: {},
      loadingAnimation: false,
      animationBase: null,
    };
  },
  computed: {
    disableAddResult() {
      if (this.roundOpen && this.addingResult) return true;
      return false;
    },
    ...mapGetters({
      success: 'round/isSuccess',
      reds: 'reports/getReds',
      getErrorCodes: 'providerErrors/getErrorCodes',
      isIncidence: 'round/isIncidence',
    }),
  },
  methods: {
    async handleOut() {
      this.loadingAnimation = true;

      const { _id } = this.rouletteFisic;

      this.animationBase = this.animationBase === 0 ? 1 : 0;

      try {
        const { status } = await this.$http.put(`roulettes/fisics/${_id}`, {
          animation: this.animationBase,
        });

        if (status > 201) throw new Error('Error updating');

        this.loadingAnimation = false;
      } catch (error) {
        console.log('ERROR UPDATING', error);
        this.loadingAnimation = false;
      }
    },
    async addResult() {
      this.addingResult = true;
      if (this.numberSelected === null) {
        this.addingResult = false;
        return Swal.fire('Seleccione un numero');
      }
      if (this.currentRound === null) return Swal.fire('No hay ronda');
      if (!this.PROVIDER_ID) return Swal.fire('No hay ROULETTE ID');

      await this.closeRound({
        ID_Ronda: this.currentRound,
        ID_Ruleta: this.PROVIDER_ID,
        Resultado: this.numberSelected,
      });

      if (!this.success) {
        this.addingResult = false;
        return Swal.fire('Error cerrando ronda', '', 'error');
      }

      if (this.isIncidence) {
        const { isConfirmed } = await Swal.fire({
          title: 'INCIDENCIA CREADA',
          text: '¿Desea iniciar una ronda nueva?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'SI',
          cancelButtonText: 'NO',
        });

        if (!isConfirmed) {
          this.addingResult = false;
          return false;
        }
      }

      Swal.fire({
        title: 'Ronda cerrada',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });

      let color = this.reds.includes(this.numberSelected)
        ? 'red'
        : [0, 37].includes(this.numberSelected)
          ? 'green'
          : 'black';
      this.results.unshift({ result: this.numberSelected, color });

      this.cleanBetInterval();
      this.progressValue = 30;
      this.betMessage = 'RONDA CERRADA';
      this.barColor = 'danger';
      this.numberSelected = null;
      this.addingResult = false;
      this.timeToBet = 30;

      setTimeout(() => {
        this.createBetInterval();
        this.createRound();
      }, 2000);
    },
    handleClick({ number }) {
      this.numberSelected = number;
    },
    addNumbers() {
      const twins = [
        {
          numbers: [8, 10, 11, 13, 17, 20, 26, 29, 28, 31],
          color: 'black',
        },
        {
          numbers: [9, 12, 16, 18, 19, 21, 27, 30],
          color: 'red',
        },
      ];

      const singles = [
        {
          numbers: [2, 4, 6, 15, 22, 24, 33, 35],
          color: 'black',
        },
        {
          numbers: [1, 3, 5, 7, 14, 23, 25, 32, 34, 36],
          color: 'red',
        },
      ];

      const tableNumbers = [];

      for (let number = 1; number <= 36; number++) {
        if (singles[0].numbers.includes(number)) {
          const info = {
            number,
            color: singles[0].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (singles[1].numbers.includes(number)) {
          const info = {
            number,
            color: singles[1].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (twins[0].numbers.includes(number)) {
          const info = {
            number,
            color: twins[0].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (twins[1].numbers.includes(number)) {
          const info = {
            number,
            color: twins[1].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
      }

      if (this.doubleZeroRoulette) {
        tableNumbers.unshift(
          {
            number: 0,
            color: 'green',
            type: 'plenoNumbers',
            label: 'Pleno',
          },
          {
            number: 37,
            color: 'green',
            type: 'plenoNumbers',
            label: 'Pleno',
          }
        );
      } else {
        tableNumbers.unshift({
          number: 0,
          color: 'green',
          type: 'plenoNumbers',
          label: 'Pleno',
        });
      }

      this.buttons = tableNumbers;
    },
    createSocket() {
      this.socket = io(this.SOCKET_URL, {
        transports: ['websocket'],
        query: {
          rouletteId: this.ROULETTE_ID,
          userId: 'MANUAL-RESULT',
        },
      });

      this.socketEvents();
    },
    socketEvents() {
      this.socket.on('round:start', (data) => {
        console.log('round:start', data);
        this.roundOpen = true;
        // DONT DUPLICATE INTERVALS
        if (!this.timeToBet && this.runningInterval) {
          this.cleanBetInterval();
        }

        const { ID_Ronda, identifierNumber, start_date, end_date } = data.round;
        this.roundsIds.unshift({
          index: this.roundsIds.length,
          PROVIDER_ROUND: ID_Ronda,
          IDENTIFIER: identifierNumber,
        });
        this.currentRound = ID_Ronda;
        // TIME
        const initialTime = new Date(start_date);
        const endTime = new Date(end_date);
        const totalTime = endTime.getTime() - initialTime.getTime();
        this.timeToBet = totalTime / 1000;
        // END TIME

        // INTERVAL
        this.intervalo = setInterval(() => {
          if (!this.timeToBet) {
            this.roundOpen = false;
            this.betMessage = 'APUESTAS CERRADAS';
            this.progressValue = 30;
            this.barColor = 'danger';

            return () => this.cleanBetInterval();
          }
          this.betMessage = 'RONDA ABIERTA';
          this.barColor = 'success';

          this.runningInterval = true;
          this.timeToBet--;
          this.progressValue = this.timeToBet;
        }, 1000);
      });

      this.socket.on('round:end', ({ result }) => {
        this.betMessage = 'ESPERE LA SIGUIENTE RONDA';
        this.barColor = 'danger';

        let color = this.reds.includes(result)
          ? 'red'
          : [0, 37].includes(result)
            ? 'green'
            : 'black';
        this.results.unshift({ result, color });
      });
    },
    createBetInterval() {
      this.intervalo = setInterval(() => {
        if (!this.timeToBet) {
          this.roundOpen = false;
          this.betMessage = 'APUESTAS CERRADAS';
          this.progressValue = 30;
          this.barColor = 'danger';

          return () => this.cleanBetInterval();
        }
        this.betMessage = 'RONDA ABIERTA';
        this.barColor = 'success';

        this.runningInterval = true;
        this.timeToBet--;
        this.progressValue = this.timeToBet;
      }, 1000);
    },
    cleanBetInterval() {
      return clearInterval(this.intervalo);
    },
    getRouletteFisics() {
      this.$http
        .get('roulettes/fisics/get')
        .then(({ data }) => {
          if (!data.ok) return console.log('No hay Ruletas Fisicas');

          if (!data.rouletteFisics) {
            return console.log('No hay rouletteFisics', data.rouletteFisics);
          }
          this.rouletteFisic = data.rouletteFisics.find(
            (el) => el.providerId === this.PROVIDER_ID
          );

          this.animationBase = this.rouletteFisic.animation;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createRound() {
      try {
        const { data } = await this.$http.put('round/start', {
          ID_Ronda: crypto.randomUUID(),
          ID_Ruleta: this.PROVIDER_ID,
          Fecha: new Date().toString(),
          Giro: '0',
          Rpm: '22',
          Error: '0',
          Resultado: '99',
        });

        const { ID_Ronda, ok } = data;

        if (ok) {
          this.roundsIds.unshift({
            index: this.roundsIds.length,
            PROVIDER_ROUND: ok,
            IDENTIFIER: 0,
          });

          this.betMessage = `RONDA: ${ok} ESPERANDO RESULTADO`;
          this.currentRound = ok;
        }

        if (ID_Ronda) {
          this.roundsIds.unshift({
            index: this.roundsIds.length,
            PROVIDER_ROUND: ID_Ronda,
            IDENTIFIER: 0,
          });

          this.betMessage = `RONDA: ${ID_Ronda} ESPERANDO RESULTADO`;
          this.currentRound = ID_Ronda;
        }

        this.timeToBet = 30;
      } catch (error) {
        console.log('ERROR CREATE ROUND', error);
      }
    },
    async handleProviderError() {
      this.$refs.addIncidenceComponent.modal = true;
    },
    async handleIncidence(errorCode = 0) {
      this.numberSelected = errorCode;
      this.addResult();
    },
    ...mapActions({
      closeRound: 'round/closeRound',
      fetchProviderErrors: 'providerErrors/fetchErrorCodes',
    }),
  },
  mounted() {
    this.addNumbers();
    // this.createSocket()
    this.createRound();
    this.getRouletteFisics();
    this.fetchProviderErrors();
  },
  beforeDestroy() {
    this.socket = null;
    this.cleanBetInterval();
  },
};
</script>

<style scoped>
.loader {
  background: rgba(133, 133, 133, 0.548);
  color: #000;
}

.openbets {
  transform: scale(1);
}

.closebets {
  transform: scale(1);
}

.zeroBtn {
  /* grid-row: 1/4;
  grid-column: 1/2; */
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.zeroBtn > * {
  position: relative;
}

.zeroBtn .HoverBtns {
  top: 0;
  left: 0;
}

.HoverBtns {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    45deg,
    rgba(0, 128, 255, 0.8),
    rgba(0, 85, 255, 0.8)
  );
  z-index: 10;
}

.zeroBtn > * {
  flex-grow: 1;
}

.inactive {
  background: transparent;
  pointer-events: none;
}

.active {
  pointer-events: all;
}

.numbers-pleno {
  display: flex;
  height: 100%;
}

.numbers-pleno > div {
  width: 6rem;
  max-width: 200px;
  height: 6rem;
  max-height: 200px;
  margin: 1px;
  font-size: 2.8rem;
  font-weight: bolder;
}

.btn-container {
  position: relative;
}

.green {
  color: white;
  background: green;
  border: none;
}

.red {
  background: red;
  color: white;
}

.black {
  background: black;
  color: white;
}

.innerBtn {
  width: 90%;
  height: 90%;
  position: absolute;
  border: none;
  background: linear-gradient(
    0deg,
    rgba(238, 174, 202, 0) 0%,
    rgba(133, 133, 133, 0.44) 100%
  );
  z-index: 30;
}

.resultZone {
  display: flex;
  justify-content: space-between;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  padding: 0 20px;
  text-align: left;
}

.results {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  align-items: center;
}

.rounds-container {
  max-height: 4rem;
  overflow-y: scroll;
}

.results > div {
  width: max-content;
  height: max-content;
  background: red;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  margin: 0 5px;
  padding: 5px;
}

.table-game {
  display: grid;
  height: 100vh;
  grid-template-rows: 10% 90%;
  grid-template-columns: 60% 40%;
}

.head {
  grid-column: 1/3;
}

.foot {
  display: grid;
  grid-template-rows: 2fr repeat(3, 1fr);
}

.tablero {
  display: flex;
  flex-wrap: wrap;
}

.config-result {
  font-size: 4rem;
}
</style>
